import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

import Link from 'components/link';
import { urlFor } from 'lib/sanity';
import { newsPostUrl } from 'lib/urls';
import { Post } from 'sanity-schema';

export interface PostItemProps {
  post: Post;
}

const PostItem: FC<PostItemProps> = ({ post }) => {
  const { locale } = useRouter();

  return (
    <Flex direction="column" w="full">
      {post.mainImage && (
        <Box w="full" position="relative" overflow="hidden" maxH="600px" mb={5}>
          <Link href={newsPostUrl(post, locale)}>
            <Image
              unoptimized
              src={urlFor(post.mainImage).minHeight(600).url()}
              alt={post.title?.[locale]}
              w="full"
              h="auto"
              objectFit="cover"
              transition=".5s"
              transform="scale(1.05)"
              _hover={{
                filter: 'blur(2px)',
                transform: 'scale(1)',
              }}
            />
          </Link>
        </Box>
      )}
      <Link href={newsPostUrl(post, locale)}>
        <Heading as="h3" fontSize="2xl" mb={4}>
          {post.title?.[locale]}
        </Heading>
      </Link>
      <Box h="1px" w="25%" bg="darkGray.200" mb={4} />
      {post.seo?.description?.[locale] && (
        <Text color="darkGrayText" mb={4} noOfLines={2}>
          {post.seo?.description?.[locale]}
        </Text>
      )}
    </Flex>
  );
};

export default PostItem;
