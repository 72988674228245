import { Flex, Heading, Text, useBreakpointValue } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, { FC } from 'react';

import ChevronRight from 'components/i/ChevronRight';
import Link from 'components/link';
import PageContainer from 'components/shared/page-container';

const Section: FC<{ title: string; href: string }> = ({ title, href, children }) => {
  const { t } = useTranslation('common');
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <>
      <PageContainer px={{ base: 0, lg: 4 }} mt={8}>
        <Link
          as={Flex}
          href={href}
          bg="darkGray.200"
          mb={8}
          px={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading
            as="h2"
            py={4}
            textAlign="center"
            fontSize={{ base: 'sm', lg: 'md' }}
            textTransform="uppercase"
            color="black"
          >
            {title}
          </Heading>
          <Flex
            color="black"
            textTransform="uppercase"
            fontWeight="semibold"
            fontSize="md"
            align="center"
          >
            {!isMobile && (
              <Text as="span" mr={2}>
                {t('seeAll')}
              </Text>
            )}
            <ChevronRight
              fill="currentColor"
              height="1.25rem"
              width="1.25rem"
              style={{ marginBottom: '2px' }}
            />
          </Flex>
        </Link>
      </PageContainer>
      <PageContainer as="section" mb={8}>
        {children}
      </PageContainer>
    </>
  );
};

export default Section;
