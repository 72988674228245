import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import * as Sentry from '@sentry/node';

import { Alert, Category, Faq, Page, PageWithImages, Post } from 'sanity-schema';

const sanity = sanityClient({
  projectId: 'szgg9g15',
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  apiVersion: '2021-03-25',
  // token: process.env.SANITY_TOKEN,
  useCdn: process.env.NODE_ENV === 'production',
});

const builder = imageUrlBuilder(sanity);

export default sanity;

export function urlFor(source: SanityImageSource) {
  return builder.image(source);
}

export async function getPage(id: string, lang = 'it'): Promise<Page | null> {
  try {
    return (sanity.fetch(`
      *[_type == "page" && _id == "${id}" && publish.${lang} == true][0]
    `) as unknown) as Page | null;
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        function: 'getPage',
        id,
        lang,
      },
    });
    console.error(e);
    return null;
  }
}

export async function getPageWithImages(id: string, lang = 'it'): Promise<PageWithImages | null> {
  try {
    return (sanity.fetch(`
      *[_type == "pageWithImages" && _id == "${id}" && publish.${lang} == true][0]
    `) as unknown) as PageWithImages | null;
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        function: 'getPageWithImages',
        id,
        lang,
      },
    });
    console.error(e);
    return null;
  }
}

export async function getFAQ(lang = 'it'): Promise<Faq | null> {
  try {
    return (sanity.fetch(`
      *[_type == "faq" && _id == "faq" && publish.${lang} == true][0]
    `) as unknown) as Faq | null;
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        function: 'getFAQ',
        lang,
      },
    });
    console.error(e);
    return null;
  }
}

export async function getCategories(lang = 'it'): Promise<Category[]> {
  try {
    return (sanity.fetch(`
      *[_type == "category" && defined(title.${lang})]{
        _id,
        slug,
        title
      } | order(title.${lang} asc)
    `) as unknown) as Category[] | null;
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        function: 'getCategories',
        lang,
      },
    });
    console.error(e);
    return [];
  }
}

export async function getAllPosts(): Promise<Post[]> {
  try {
    const date = new Date().toISOString();
    const params = [
      '_type == "post"',
      `(publish.it == true || publish.en == true)`,
      `(publishedAt <= "${date}" || _createdAt <= "${date}")`,
    ];

    return sanity.fetch<Post[]>(
      `
        *[${params.join(' && ')}]{
          slug,
          publish
        }
      `,
    );
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        function: 'getAllPosts',
      },
    });
    console.error(e);
    return [];
  }
}

export async function getPosts(
  lang = 'it',
  options: {
    page?: number;
    perPage?: number;
    search?: string;
    category?: string;
    excludeIds?: string[];
  } = {},
): Promise<Post[]> {
  const { page = 1, perPage = 5, search, category, excludeIds } = options;

  try {
    const from = (page - 1) * perPage;
    const to = from + perPage;

    return sanity.fetch<Post[]>(
      `
      *[${getPostParams(lang, search, category, excludeIds)}]{
        _id,
        _createdAt,
        slug,
        title,
        publishedAt,
        mainImage,
        seo,
        author->{name}
      } | order(publishedAt desc) | order(_createdAt desc) [${from}...${to}]
    `,
    );
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        function: 'getPosts',
        lang,
        page: options?.page,
        perPage: options?.perPage,
        search: options?.search,
        category: options?.category,
        excludeIds: options?.excludeIds,
      },
    });
    console.error(e);
    return [];
  }
}

export async function getPost(slug: string, lang: string): Promise<Post | null> {
  try {
    return sanity.fetch<Post>(`
      *[_type == "post" && slug.${lang}.current == "${slug}"]{
        ...,
        author->{name},
	      categories[]->
      }[0]
    `);
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        function: 'getPost',
        slug,
        lang,
      },
    });
    console.error(e);
    return null;
  }
}

export async function getAllPostCount(): Promise<number> {
  try {
    const date = new Date().toISOString();
    const result = await sanity.fetch(
      `
        {
          "count": count(*[
            _type == "post" &&
            (publish.it == true || publish.en == true) &&
            (defined(slug.it.current) || defined(slug.en.current)) &&
            (publishedAt <= "${date}" || _createdAt <= "${date}")
          ])
        }
      `,
    );

    return (result?.count as number) ?? 0;
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        function: 'getAllPostCount',
      },
    });
    console.error(e);
    return 0;
  }
}

export async function getAllPostsForSitemap(page = 1, perPage = 1000): Promise<Post[]> {
  const from = (page - 1) * perPage;
  const to = from + perPage;
  const date = new Date().toISOString();

  try {
    return sanity.fetch<Post[]>(`
      *[
        _type == "post" &&
        (publish.it == true || publish.en == true) &&
        (defined(slug.it.current) || defined(slug.en.current)) &&
        (publishedAt <= "${date}" || _createdAt <= "${date}")
      ]{ _updatedAt, publish, slug, mainImage{ ..., asset-> } }[${from}...${to}]
    `);
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        function: 'getAllPostsForSitemap',
        page,
        perPage,
      },
    });
    console.error(e);
    return [];
  }
}

export async function getPostCount(
  lang = 'it',
  search = '',
  categoryId?: string,
  excludeIds?: string[],
): Promise<number> {
  try {
    const result = await sanity.fetch(
      `
      {
        "count": count(*[${getPostParams(lang, search, categoryId, excludeIds)}])
      }
    `,
    );

    return (result?.count as number) ?? 0;
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        function: 'getPostCount',
        lang,
        search,
        categoryId,
        excludeIds,
      },
    });
    console.error(e);
    return 0;
  }
}

export async function getAllCategories(): Promise<Category[]> {
  try {
    return sanity.fetch(
      `
        *[_type == "category" && (defined(slug.it.current) || defined(slug.en.current))]{
          slug
        }
      `,
    );
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        function: 'getAllCategories',
      },
    });
    console.error(e);
    return [];
  }
}

export async function getAllCategoriesForSitemap(page = 1, perPage = 1000): Promise<Category[]> {
  const from = (page - 1) * perPage;
  const to = from + perPage;
  try {
    return sanity.fetch(
      `
        *[_type == "category" && (defined(slug.it.current) || defined(slug.en.current))]{
          _updatedAt,
          slug,
          mainImage{ ..., asset-> }
        }[${from}...${to}]
      `,
    );
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        function: 'getAllCategoriesForSitemap',
        page,
        perPage,
      },
    });
    console.error(e);
    return [];
  }
}

export async function getCategoryCount(): Promise<number> {
  try {
    const result = await sanity.fetch(
      `
        {
          "count": count(*[_type == "category" && (defined(slug.it.current) || defined(slug.en.current))])
        }
      `,
    );

    return (result?.count as number) ?? 0;
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        function: 'getCategoryCount',
      },
    });
    console.error(e);
    return 0;
  }
}

export async function getCategory(slug: string, lang: string): Promise<Category | null> {
  try {
    return sanity.fetch(`*[_type == "category" && slug.${lang}.current == "${slug}"][0]`);
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        function: 'getCategory',
        slug,
        lang,
      },
    });
    console.error(e);
    return null;
  }
}

export async function getLastActiveAlert(lang: string): Promise<Alert | null> {
  try {
    return sanity.fetch<Alert>(
      `
      *[_type == "alert" && publish.${lang} == true && defined(title.${lang})]{
        "title": title.${lang}
      } | order(_createdAt desc) [0]
      `,
    );
  } catch (e) {
    Sentry.captureException(e, {
      extra: {
        function: 'getLastActiveAlert',
        lang,
      },
    });
    console.error(e);
    return null;
  }
}

export function sanityLocaleField<E, T extends Record<string, E>>(
  item: T,
  locale: string,
): E | null {
  return item?.[locale] || null;
}

function getPostParams(lang = 'it', search = '', category?: string, excludeIds?: string[]): string {
  const date = new Date().toISOString();
  const params = [
    '_type == "post"',
    `publish.${lang} == true`,
    `defined(slug.${lang}.current)`,
    `(publishedAt <= "${date}" || _createdAt <= "${date}")`,
  ];

  if (search) {
    params.push(`title.${lang} match "*${search}*"`);
  }

  if (category) {
    params.push(`categories[]._ref == "${category}"`);
  }

  if (excludeIds?.length > 0) {
    const idString = excludeIds.map((id) => `"${id}"`).join(', ');
    params.push(`!(_id in [${idString}])`);
  }

  return params.join(' && ');
}
