import { chakra, Flex, Heading, HStack, Link, VStack } from '@chakra-ui/react';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';

import Money from 'components/shared/money';
import MoneyValue from 'components/shared/money-value';
import useItemTranslation from 'hooks/useItemTranslation';
import { productUrl } from 'lib/urls';
import { Product as ProductType } from 'types';

interface ProductProps {
  product: ProductType;
}

const ProductImage = chakra(NextImage, {
  shouldForwardProp: (prop) => ['width', 'height', 'src', 'alt', 'unoptimized'].includes(prop),
});

function Product({ product }: ProductProps) {
  const { localeField } = useItemTranslation();
  const { locale } = useRouter();

  let url = productUrl(product);
  if (locale === 'en') {
    url = `/en${url}`;
  }

  return (
    <VStack spacing={2}>
      <Flex flex={1}>
        <Link href={url}>
          <ProductImage
            unoptimized
            src={product.thumbnail2x?.url}
            alt={product.thumbnail.alt || localeField(product, 'name')}
            w="full"
            h="auto"
            objectFit="contain"
            objectPosition="bottom"
            width={540}
            height={386}
          />
        </Link>
      </Flex>
      <Link alignSelf="stretch" href={url}>
        <Heading as="h4" size="sm" textAlign="center" isTruncated>
          {localeField(product, 'name')}
        </Heading>
      </Link>
      {product.pricing?.priceRangeUndiscounted ? (
        <HStack spacing={2}>
          <Money
            money={product.pricing.priceRangeUndiscounted.start}
            strike={product.pricing.onSale}
          />
          {product.pricing.onSale && <Money money={product.pricing.priceRange.start} />}
        </HStack>
      ) : (
        <MoneyValue price={{ amount: 0, currency: 'EUR' }} visibility="hidden" />
      )}
    </VStack>
  );
}

export default React.memo(Product);
