import { Stack, useBreakpointValue } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, { FC } from 'react';

import { useLocalizedLink } from 'hooks/useLocalizedLink';
import { Post } from 'sanity-schema';
import PostItem from './post-item';
import Section from './section';

const PostList: FC<{ posts: Post[]; title: string }> = ({ posts, title }) => {
  const { t } = useTranslation('common');
  const max = useBreakpointValue({ base: 2, lg: 3 });
  const localizedUrl = useLocalizedLink();

  const alteredPosts = posts.slice(0, max);

  return (
    <Section title={t('pages.news')} href={localizedUrl('news')}>
      <Stack direction={{ base: 'column', md: 'row' }} spacing={8}>
        {alteredPosts.map((post) => (
          <PostItem key={post._id} post={post} />
        ))}
      </Stack>
    </Section>
  );
};

export default PostList;
