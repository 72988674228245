import { Box, Container, Flex, Image, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import SearchAutocomplete from 'components/search-autocomplete/search-autocomplete';

function Hero() {
  const router = useRouter();
  let versionLabel;
  const { t, lang } = useTranslation('common');
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const isTablet = useBreakpointValue({ base: true, md: true, lg: false });
  if (isMobile && !isTablet) versionLabel = 'mobile';
  else if (isTablet) versionLabel = 'tablet';
  else versionLabel = 'desktop';
  let videoConfig = { overlay: false, loop: false };
  let videoUrl;
  let hasVideo;
  let imgConfig = {
    it: {
      desktop: '/images/hero-desktop.jpg',
      tablet: '/images/hero.jpg',
      mobile: '/images/hero-mobile.jpg',
    },
    en: {
      desktop: '/images/hero-desktop.jpg',
      tablet: '/images/hero.jpg',
      mobile: '/images/hero-mobile.jpg',
    },
    overlay: false,
  };
  const heroMargin = {
    desktop: '-90px',
    tablet: '-58px',
    mobile: '-58px',
  };
  let imgUrl;
  let imgBelowHeader = false;
  if (process.env.NEXT_PUBLIC_HERO_VIDEO_CONFIG) {
    videoConfig = JSON.parse(process.env.NEXT_PUBLIC_HERO_VIDEO_CONFIG);
    videoUrl = videoConfig[lang][versionLabel];
    hasVideo = videoUrl && Object.keys(videoUrl).length;
    imgBelowHeader = true;
  } else if (process.env.NEXT_PUBLIC_HERO_IMG_CONFIG) {
    imgConfig = JSON.parse(process.env.NEXT_PUBLIC_HERO_IMG_CONFIG);
    imgUrl = imgConfig[lang];
    imgBelowHeader = true;
  } else {
    hasVideo = false;
    imgUrl = imgConfig[lang];
  }
  return (
    <Box
      h={['350px', '350px', '580px', '700px', '850px']}
      mt={imgBelowHeader ? heroMargin[versionLabel] : 'auto'}
      className={imgBelowHeader ? 'hero-wrapper' : 'hero-wrapper'}
      position="relative"
    >
      {hasVideo && (
        <>
          <Head>
            {Object.values(videoConfig[lang]).map((url) => (
              <link key={String(url)} rel="preload" href={String(url)} as="video" />
            ))}
          </Head>
          <div className="hero-video">
            <a className="hero-video__link" href="/shop">
              <div
                className={
                  videoConfig.overlay
                    ? 'hero-video__overlay hero-video__overlay--opaque'
                    : 'hero-video__overlay'
                }
              />
              <video
                className="hero-video__element"
                autoPlay={true}
                playsInline={true}
                muted={true}
                loop={videoConfig.loop}
                key={videoUrl}
              >
                <source src={videoUrl} />
              </video>
            </a>
          </div>
        </>
      )}
      {!hasVideo && (
        <>
          <a href={t('hero.url')}>
            <picture
              className={
                imgConfig.overlay ? 'hero-image__overlay hero-image__overlay--opaque' : 'hero-image'
              }
            >
              <source srcSet={imgUrl.mobile} media="(max-width: 48em)" />
              <source srcSet={imgUrl.tablet} media="(max-width: 62em)" />
              <Image
                unoptimized
                src={imgUrl.desktop}
                w="full"
                h="full"
                objectFit="cover"
                filter={imgBelowHeader ? 'brightness(1)' : 'brightness(0.5)'}
              />
            </picture>
          </a>
          {!imgBelowHeader && (
            <Box position="absolute" top={{ base: '20%', md: '45%', lg: '20%' }} w="full">
              <Container maxW="container.3xl" px={4} justifyContent="center">
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  spacing={{ base: 2, md: 4 }}
                  justify="center"
                >
                  <Text
                    as="span"
                    fontSize={{ base: '5xl', md: '5xl', lg: '6xl', xl: '5rem' }}
                    fontWeight="bold"
                    color="white"
                  >
                    {t('hero.1')},
                  </Text>
                  <Text
                    as="span"
                    fontSize={{ base: '5xl', md: '5xl', lg: '6xl', xl: '5rem' }}
                    fontWeight="bold"
                    color="white"
                  >
                    {t('hero.2')},
                  </Text>
                  <Text
                    as="span"
                    fontSize={{ base: '5xl', md: '5xl', lg: '6xl', xl: '5rem' }}
                    fontWeight="bold"
                    color="white"
                  >
                    {t('hero.3')}.
                  </Text>
                </Stack>
              </Container>
            </Box>
          )}
        </>
      )}
      {!isMobile && router.isReady && (
        <Flex
          position="absolute"
          h="full"
          w="full"
          direction="column"
          top={0}
          alignItems="center"
          justifyContent="center"
        >
          <Container maxW="container.sm" px={4}>
            <SearchAutocomplete
              hideCloseButton
              inputGroup={{ border: 'none', boxShadow: 'lg' }}
              input={{ border: 'none', _focus: { boxShadow: 'none' } }}
            />
          </Container>
        </Flex>
      )}
    </Box>
  );
}

export default Hero;
