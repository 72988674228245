import { Image } from '@chakra-ui/image';
import { Box, Heading, Stack, Text } from '@chakra-ui/layout';
import { useBreakpointValue } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, { FC } from 'react';

import Link from 'components/link';
import PageContainer from 'components/shared/page-container';
import useItemTranslation from 'hooks/useItemTranslation';
import { BaseCategory } from 'lib/graphql/types/BaseCategory';
import { categoryUrl } from 'lib/urls';

const CategoryBox: FC<{ category: BaseCategory }> = ({ category }) => {
  const { localeField } = useItemTranslation();
  const { t } = useTranslation('common');

  const categoryName = localeField(category, 'name');

  return (
    <Link
      href={categoryUrl(category)}
      as={Box}
      flex={1}
      role="group"
      position="relative"
      overflow="hidden"
    >
      <Box overflow="hidden" h="full">
        <Image
          unoptimized
          src={category.backgroundImage?.url}
          alt={category.backgroundImage?.alt}
          w="full"
          h="full"
          maxW="full"
          maxH={{ base: '450px', lg: '500px', xl: '600px' }}
          objectFit="cover"
          transition="all .8s linear"
          filter="brightness(0.6)"
          _groupHover={{
            transform: 'scale(1.04)',
          }}
        />
      </Box>
      <Box
        position="absolute"
        w="full"
        left="0"
        top="50%"
        transition=".5s"
        transform="translateY(-50%)"
        textAlign="center"
        color="white"
      >
        <Heading
          as="h3"
          fontWeight="bold"
          fontSize={{ base: '2xl', lg: '5xl' }}
          pb={4}
          transition="all .4s ease-in-out"
          transform={categoryName.split(' ').length > 3 ? 'translateY(20%)' : 'translateY(50%)'}
          _groupHover={{
            transform: 'translateY(0)',
          }}
        >
          {categoryName}
        </Heading>
        <Box w="full" textAlign="center">
          <Text
            as="span"
            fontSize={{ base: 'md', lg: 'xl' }}
            transition="all .4s ease-in-out"
            opacity={0}
            transform="translateY(-50%)"
            position="relative"
            pb={2}
            _groupHover={{
              opacity: 1,
              transform: 'translateY(0)',
            }}
            _after={{
              content: '""',
              borderBottom: '1px',
              borderBottomStyle: 'solid',
              borderBottomColor: 'white',
              position: 'absolute',
              right: 0,
              width: 0,
              bottom: 0,
              transition: 'width .4s linear 0s',
            }}
            _hover={{
              _after: {
                left: 0,
                width: '100%',
              },
            }}
          >
            {t('seeAll')}
          </Text>
        </Box>
      </Box>
    </Link>
  );
};

const CategorySection: FC<{ categories: BaseCategory[] }> = ({ categories }) => {
  const max = useBreakpointValue({ base: 1, lg: 2 });

  const alteredCategories = categories.slice(0, max);

  return (
    <PageContainer as="section">
      <Stack direction={{ base: 'column', lg: 'row' }} justify="center" spacing={10}>
        {alteredCategories.map((category) => (
          <CategoryBox key={category.slug} category={category} />
        ))}
      </Stack>
    </PageContainer>
  );
};

export default CategorySection;
