import { SimpleGrid, SimpleGridProps, useBreakpointValue } from '@chakra-ui/react';
import { Ref } from 'react';

import { ProductList_products_edges } from 'lib/graphql/types/ProductList';
import Product from './product';

interface ProductListProps {
  products: ProductList_products_edges[];
  scrollContainerRef?: Ref<HTMLDivElement>;
  gridProps?: SimpleGridProps;
  maxProducts?: Record<string, number> | number[];
}

function ProductList({
  products,
  scrollContainerRef,
  gridProps,
  maxProducts = [],
}: ProductListProps) {
  const max = useBreakpointValue<number>(maxProducts);

  const alteredProducts = max ? products.slice(0, max) : products;

  return (
    <SimpleGrid ref={scrollContainerRef} columns={[2, 2, 2, 3, 4]} spacing={5} {...gridProps}>
      {alteredProducts.map((product) => (
        <Product key={product.node.id} product={product.node} />
      ))}
    </SimpleGrid>
  );
}

export default ProductList;
