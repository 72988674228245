import {
  Box,
  BoxProps,
  Heading,
  HeadingProps,
  keyframes,
  Link,
  usePrefersReducedMotion,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React from 'react';

import { useLocalizedLink } from 'hooks/useLocalizedLink';
import rangeMap from 'lib/range-map';

export interface MarqueeProps {
  text: string;
  containerProps?: BoxProps;
  headingProps?: HeadingProps;
  reverse?: boolean;
}

const marquee = keyframes`
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
`;

const marqueeReversed = keyframes`
  from { transform: translateX(-50%); }
  to { transform: translateX(0); }
`;

function Marquee({ text, containerProps, headingProps, reverse }: MarqueeProps) {
  const prefersReducedMotion = usePrefersReducedMotion();
  const localizeLink = useLocalizedLink();
  const { locale } = useRouter();

  const animation = prefersReducedMotion
    ? undefined
    : `${reverse ? marqueeReversed : marquee} infinite 30s linear`;

  let url = localizeLink('shop');
  if (locale === 'en') {
    url = `/en${url}`;
  }

  return (
    <Box bg="black" overflow="hidden" {...containerProps}>
      <Link href={url}>
        <Heading
          as="h2"
          py={4}
          textAlign="center"
          fontSize="md"
          textTransform="uppercase"
          color="white"
          animation={animation}
          whiteSpace="nowrap"
          {...headingProps}
        >
          {rangeMap(50, () => text).join(' - ')}
        </Heading>
      </Link>
    </Box>
  );
}

export default Marquee;
