import { Text, TextProps, TypographyProps } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { FC, useMemo } from 'react';

import { useShop } from 'hooks';
import { PriceValue } from 'types';

export interface MoneyValueProps extends TextProps {
  gray?: boolean;
  price?: PriceValue;
  fontSize?: TypographyProps['fontSize'];
  discount?: boolean;
}

const MoneyValue: FC<MoneyValueProps> = ({ gray, price, fontSize, discount, ...rest }) => {
  const router = useRouter();
  const { displayPrice } = useShop();
  const priceValue = useMemo(
    () => displayPrice(discount ? -price.amount : price.amount, router.locale),
    [discount, displayPrice, price.amount, router.locale],
  );

  return (
    <Text
      as="span"
      color={gray ? 'darkGray.500' : 'primary'}
      fontWeight={{ base: 'semibold', lg: 'bold' }}
      fontSize={fontSize || 'sm'}
      {...rest}
    >
      {priceValue}
    </Text>
  );
};

export default MoneyValue;
