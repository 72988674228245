import { GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import CategorySection from 'components/home/category-section';
import Hero from 'components/home/hero';
import PostList from 'components/home/post-list';
import Section from 'components/home/section';
import SiteLayout from 'components/layout/site-layout';
import ProductList from 'components/products/product-list';
import Marquee from 'components/shared/marquee';
import PageContainer from 'components/shared/page-container';
import { useShop } from 'hooks';
import { initializeApollo } from 'lib/apollo';
import { homeCollectionsQuery } from 'lib/graphql/queries/products';
import { HomeCollections } from 'lib/graphql/types/HomeCollections';
import { getPosts } from 'lib/sanity';
import { categoryUrl } from 'lib/urls';
import { getCurrencyExchangeRates } from 'prisma/api/currency';
import { Post } from 'sanity-schema';
import { PageComponent } from 'types';

export interface HomeProps {
  homeCollection: HomeCollections;
  posts: Post[];
}

const Home: PageComponent<HomeProps> = ({ homeCollection, posts }) => {
  const { locale } = useRouter();
  const { t } = useTranslation('home');
  const { displayPrice } = useShop();

  return (
    <>
      <NextSeo
        title={t('seo.title')}
        description={t('seo.description')}
        robotsProps={{ maxSnippet: -1, maxImagePreview: 'large', maxVideoPreview: -1 }}
        openGraph={{
          locale,
          type: 'website',
          title: t('seo.title'),
          description: t('seo.description'),
        }}
      />
      <Hero />
      {homeCollection?.newProducts?.edges?.length > 0 && (
        <>
          <Marquee text={t('newProducts')} />
          <PageContainer as="section" my={8}>
            <ProductList
              products={homeCollection.newProducts.edges}
              maxProducts={{ base: 8, lg: 9, xl: 12 }}
            />
          </PageContainer>
        </>
      )}
      {posts?.length > 0 && <PostList title={t('common:pages.news')} posts={posts} />}
      {(homeCollection?.categoryBox1 || homeCollection?.categoryBox2) && (
        <CategorySection
          categories={[homeCollection?.categoryBox1, homeCollection?.categoryBox2].filter(
            (category) => category && category.backgroundImage?.url,
          )}
        />
      )}
      {homeCollection?.productsSection2?.edges?.length > 0 && homeCollection?.categorySection2 && (
        <Section
          title={t('sneakersSection', {
            value: displayPrice(250, locale, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
          })}
          href={`${categoryUrl(homeCollection.categorySection2)}?price=0-250`}
        >
          <ProductList
            products={homeCollection?.productsSection2?.edges}
            maxProducts={{ base: 4, lg: 6, xl: 8 }}
          />
        </Section>
      )}
      {(homeCollection?.categoryBox3 || homeCollection?.categoryBox4) && (
        <CategorySection
          categories={[homeCollection?.categoryBox3, homeCollection?.categoryBox4].filter(
            (category) => category && category.backgroundImage?.url,
          )}
        />
      )}
    </>
  );
};

export const getStaticProps: GetStaticProps = async (ctx) => {
  const apollo = initializeApollo(null, {
    locale: ctx.locale,
  });

  const [{ data: homeCollection }, conversionRates, posts] = await Promise.all([
    apollo
      .query<HomeCollections>({
        query: homeCollectionsQuery,
      })
      .catch((e) => ({ data: null })),
    getCurrencyExchangeRates(),
    getPosts(ctx.locale, { perPage: 3 }),
  ]);

  return {
    props: {
      homeCollection,
      conversionRates,
      posts,
    },
    revalidate: 2600 * 24, // 1 day,
  };
};

Home.getLayout = (page) => (
  <SiteLayout
    seo={(t) => ({
      title: t('seo.home.title'),
      description: t('seo.home.description'),
    })}
    header={{ hideSearch: true }}
  >
    {page}
  </SiteLayout>
);

export default Home;
