import * as React from 'react';

function SvgChevronRight(props: React.SVGProps<SVGSVGElement>, svgRef?: React.Ref<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      ref={svgRef}
      {...props}
    >
      <path d="M5 20a.5.5 0 01-.354-.853l8.646-8.646-8.646-8.646a.5.5 0 01.707-.707l9 9a.5.5 0 010 .707l-9 9a.498.498 0 01-.354.146z" />
    </svg>
  );
}

const ForwardRef = React.forwardRef(SvgChevronRight);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
